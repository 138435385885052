import { render, staticRenderFns } from "./PageHasHeroWithImage.vue?vue&type=template&id=2117e39b&scoped=true"
import script from "./PageHasHeroWithImage.vue?vue&type=script&lang=js"
export * from "./PageHasHeroWithImage.vue?vue&type=script&lang=js"
import style0 from "./PageHasHeroWithImage.vue?vue&type=style&index=0&id=2117e39b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2117e39b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroLoader: require('/vercel/path0/components/loaders/HeroLoader.vue').default,Sidebar: require('/vercel/path0/components/general/navigation/Sidebar.vue').default,ComponentLoader: require('/vercel/path0/components/loaders/ComponentLoader.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
